.about {
    height: 80vh;
    display: flex;
    justify-content: center;
    margin-top: 10rem;
}

.about-container {
    margin-left: 7rem;
    margin-right: 7rem;
    display: flex;
    flex-direction: row;
}

.about-rightside {
    flex: 50%;
    display: flex;
    flex-direction: row;
}

.about-leftside {
    flex: 50%;
    display: flex;
    flex-direction: column;
}

.about-title {
    font-size: 3rem;
}

.about-text {
    margin-left: 0.5rem;
    margin-top: 2rem;
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 400;
}

.about-image {
    margin-top: 3rem;
    margin-left: 3rem;
    border-radius: 5%;
}

@media only screen and (min-width: 1700px) {
    .about {
        margin-top: 25rem;
        margin-left: 12rem;
    }
    .about-text {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1600px) {
    .about {
        margin-bottom: 9rem;
    }
}

@media only screen and (min-width: 1350px) and (max-width: 1500px) {
    .about {
        margin-top: 18rem;
        margin-left: 4rem;
        margin-bottom: 7rem;
    }
    .about-text {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1501px) and (max-width: 1699px) {
    .about {
        margin-top: 20rem;
        margin-left: 4rem;
    }
    .about-text {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
    .about-container {
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 2.5rem;
    }

    .about-rightside {
        flex: 0%;
    }

    .about-leftside {
        flex: 100%;
    }

    .about-title {
        font-size: 2rem;
    }

    .about-text {
        margin-left: 0rem;
        margin-right: 1.5rem;
        margin-top: 2rem;
        font-size: 1.3rem;
        line-height: 1.5rem;
        text-align: justify;
    }

    .about-image {
        width: 200px;
        height: 200px;
        display: none;
    }
}

@media only screen and (min-width: 0px) and (max-width: 399px) {
    .about {
        margin-top: 8rem;
        padding-bottom: 2rem;
    }

    .about-container {
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 0rem;
    }

    .about-rightside {
        flex: 0%;
    }

    .about-leftside {
        flex: 100%;
    }

    .about-title {
        font-size: 2rem;
    }

    .about-text {
        margin-left: 0rem;
        margin-right: 1.5rem;
        margin-top: 2rem;
        font-size: 1.3rem;
        line-height: 1.5rem;
        text-align: justify;
    }

    .about-image {
        width: 200px;
        height: 200px;
        display: none;
    }
}
