.bio-surge-container {
  margin-top: 3rem;
  background-color: #f1f5f7;
}

.bio-surge-title {
  text-align: center;
  font-size: 2rem;
}

.bio-surge-dash {
  width: 8rem;
  height: 0.2rem;
  background-color: #228be6;
  margin: auto;
}

.bio-surge-infos {
  display: flex;
  margin: 3rem 4rem;
  font-size: 0.95rem;
}

.bio-surge-leftside {
  flex: 30%;
  text-align: end;
  padding-right: 3rem;
  margin-left: 4rem;
}

.bio-surge-rightside {
  flex: 70%;
  margin-right: 8rem;
}

@media only screen and (min-width: 1700px) {
  .bio-surge-infos {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .bio-surge-dash {
    width: 7rem;
  }

  .bio-surge-infos {
    display: flex;
    flex-direction: column;
    margin: 4rem 0rem;
    font-size: 1rem;
  }

  .bio-surge-leftside {
    flex: 0;
    text-align: justify;
    padding-right: 0rem;
    margin-left: auto;
    margin-right: auto;
  }

  .bio-surge-rightside {
    flex: 0;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
}
