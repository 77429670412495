.bio-info-container {
  /* background-color: #228be673; */
  display: flex;
  border-top-right-radius: 20rem;
  padding-bottom: 6rem;
}

.bio-info-leftside {
  flex: 50%;
  padding-right: 3rem;
  text-align: justify;
  padding-top: 2rem;
  padding-left: 8rem;
}

.bio-info-rightside {
  flex: 50%;
  padding-left: 8rem;
  padding-top: 0rem;
  padding-left: 8rem;
}

.bio-info-title {
  margin-bottom: 3rem;
  color: white;
}

.bio-info-info1,
.bio-info-info2 {
  font-size: 1.25rem;
  color: white;
}

.bio-info-info1 {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 1700px) {
  .bio-info-container {
    padding-bottom: 21rem;
  }
  .bio-info-info1,
  .bio-info-info2 {
    font-size: 1.5rem;
  }
  .bio-info-leftside {
    padding-right: 3rem;
    padding-top: 2rem;
    padding-left: 22rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .bio-info-container {
    flex-direction: column;
    border-top-right-radius: 5rem;
    background-position: -20rem;
  }

  .bio-info-leftside {
    flex: 0;
    padding-right: 1.5rem;
    text-align: justify;
    padding-top: 3rem;
    padding-left: 1.5rem;
  }

  .bio-info-rightside {
    flex: 0;
    padding-left: 8rem;
    padding-top: 0rem;
    padding-left: 8rem;
  }

  .bio-info-info1,
  .bio-info-info2 {
    font-size: 1.1rem;
    color: white;
  }
}
