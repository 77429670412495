.feed-info-container {
  background-color: #228be673;
  display: flex;
  border-top-right-radius: 20rem;
  padding-bottom: 12rem;
  margin-bottom: -3rem;
  background-position-y: -100px;
}

.feed-info-leftside {
  flex: 50%;
  padding-right: 3rem;
  text-align: justify;
  padding-top: 2rem;
  padding-left: 8rem;
}

.feed-info-rightside {
  flex: 50%;
  padding-left: 8rem;
  padding-top: 0rem;
  padding-left: 8rem;
}

.feed-info-title {
  margin-bottom: 3rem;
  color: white;
}

.feed-info1,
.feed-info2 {
  font-size: 1.25rem;
  color: white;
}

.feed-info-info1 {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 1700px) {
  .feed-info-container {
    padding-bottom: 20rem;
    margin-bottom: 0rem;
  }
  .feed-info-leftside {
    padding-top: 2rem;
    padding-left: 13rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .feed-info-container {
    display: flex;
    flex-direction: column;
    border-top-right-radius: 5rem;
    padding-bottom: 3rem;
    margin-bottom: 0rem;
    background-position: -10rem;
  }

  .feed-info-leftside {
    flex: 0;
    padding-right: 1.5rem;
    text-align: justify;
    padding-top: 2rem;
    padding-left: 1.5rem;
  }

  .feed-info-rightside {
    flex: 0;
    padding-left: 0rem;
    padding-top: 0rem;
    padding-left: 0rem;
  }

  .feed-info-title {
    margin-bottom: 3rem;
    font-size: 1.25rem;
  }

  .feed-info1,
  .feed-info2 {
    font-size: 1rem;
  }

  .feed-info-info1 {
    margin-bottom: 2rem;
  }
}
