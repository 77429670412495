.biovideos-container {
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.biovideos-title {
  margin-left: 8rem;
  font-size: 2rem;
  margin-bottom: 4rem;
}

.biovideos-video {
  display: flex;
  flex-direction: row;
}

.biovideos-video1 {
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.biovideos-video2 {
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1700px) {
  .biovideos-video1 {
    margin-bottom: 3rem;
    margin-left: 14rem;
    margin-right: 5rem;
  }

  .biovideos-video2 {
    margin-left: 5rem;
    margin-right: 14rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .biovideos-title {
    margin-left: 1.5rem;
    font-size: 1.5rem;
  }

  .biovideos-video {
    display: flex;
    flex-direction: column;
  }

  .video-react {
    width: 300px !important;
  }
}
