.feed-session {
  padding-top: 1rem;
  background-color: #d8d8d8;
  background-image: url("../../assets/corpo.png");
  background-repeat: no-repeat;
  background-position: 850px;
}

.feed-session-container {
  margin-left: 8rem;
  margin-right: 30rem;
  padding-bottom: 2rem;
}

.feed-session-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  padding-top: 2rem;
}

/* .feed-session-image {
  background-color: red;
} */

.feed-session-container p {
  text-align: justify;
}

@media only screen and (min-width: 1400px) and (max-width: 1700px) {
  .feed-session {
    background-position: 1150px;
  }
}

@media only screen and (min-width: 1700px) {
  .feed-session {
    background-position: 1420px;
    height: 70vh;
  }
  .feed-session-container p {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .feed-session {
    background-image: none;
  }

  .feed-session-container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-bottom: 2rem;
  }

  .feed-session-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    padding-top: 2rem;
  }
}
