.material {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transform: rotate(90deg);
  translate: 850px -250px 0px;
  width: 100px;
}

@keyframes rotate {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.dna {
  /*line*/
  position: relative;
  width: 1px;
  height: 100px;
  border: 1px dotted #e7e7e7;
  box-shadow: 0 0 15px #e7e7e7;
  background: transparent;
  margin: 0px 10px;
  animation: rotate 3s linear infinite;
}
.dna::before {
  /*top dot*/
  content: "";
  position: absolute;
  top: -2px;
  left: -5px;
  width: 10px;
  height: 10px;
  background: #1cffb3;
  border-radius: 50%;
  box-shadow: 0 0 15px #1cffb3;
}
.dna::after {
  /*bottom dot*/
  content: "";
  position: absolute;
  bottom: -2px;
  left: -5px;
  width: 10px;
  height: 10px;
  background: #0ff;
  border-radius: 50%;
  box-shadow: 0 0 12px #0ff;
}

.dna:nth-child(1) {
  animation-delay: -0.15s;
}
.dna:nth-child(2) {
  animation-delay: -0.3s;
}
.dna:nth-child(3) {
  animation-delay: -0.45s;
}
.dna:nth-child(4) {
  animation-delay: -0.6s;
}
.dna:nth-child(5) {
  animation-delay: -0.75s;
}
.dna:nth-child(6) {
  animation-delay: -0.9s;
}
.dna:nth-child(7) {
  animation-delay: -1.05s;
}
.dna:nth-child(8) {
  animation-delay: -1.2s;
}
.dna:nth-child(9) {
  animation-delay: -1.35s;
}
.dna:nth-child(10) {
  animation-delay: -1.5s;
}
.dna:nth-child(11) {
  animation-delay: -1.65s;
}
.dna:nth-child(12) {
  animation-delay: -1.8s;
}
.dna:nth-child(13) {
  animation-delay: -1.95s;
}
.dna:nth-child(14) {
  animation-delay: -2.1s;
}
.dna:nth-child(15) {
  animation-delay: -2.25s;
}
.dna:nth-child(16) {
  animation-delay: -2.3s;
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .material {
    display: none;
  }
}
