.home {
  width: 98.8vw;
  height: 100vh;
  background-image: url(../assets/family.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 3rem;
}

.home-container {
  margin-top: 20vh;
  margin-left: 7rem;
  color: whitesmoke;
}

.home-title {
  font-weight: 800;
  font-size: 4.4rem;
  margin-bottom: 0rem;
}

.home-subtitle {
  font-size: 1.3rem;
  margin-left: 1rem;
  margin-top: 0rem;
}

.home-button {
  margin-top: 0rem;
  margin-left: 1rem;
  min-width: 10rem;
  max-width: 15rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: #39768a 1px solid;
  background-color: #569099;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 1.1rem;
  color: whitesmoke;
}

.home-button:hover {
  cursor: pointer;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.6);
  transition: box-shadow 0.5s;
}

.btn-mag {
  background-color: #27445a;
}
.btn-feed {
  background-color: #27445a;
}

@media only screen and (min-width: 1700px) {
  .home {
    width: 99.2vw;
  }
  .home-container {
    margin-top: 27vh;
    margin-left: 20rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .home {
    width: 100vw;
  }

  .home-container {
    margin-top: 18vh;
    margin-left: 1.5rem;
  }

  .home-title {
    font-size: 2rem;
    width: 320px;
  }

  .home-subtitle {
    font-size: 1.3rem;
    margin-left: 1.7rem;
  }

  .home-button {
    margin-left: 2.5rem;
    margin-top: 1rem;
    height: 3rem;
    width: 300px;
  }
}
