.feedvideos-container {
  padding-top: 0rem;
  padding-bottom: 4rem;
}

.feedvideos-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 4rem;
  width: 40rem;
  margin-left: 8rem;
}

.feedvideos-video {
  display: flex;
}

.feedvideos-video1 {
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .feedvideos-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 4rem;
    width: auto;
    margin-left: 1.5rem;
  }

  .feedvideos-video {
    display: flex;
  }

  .feedvideos-video1 {
    margin-right: auto;
    margin-left: auto;
  }
}
