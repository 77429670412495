.feed-home {
  height: 85vh;
}

.feed-home-title {
  margin-top: 8rem;
  text-align: center;
  letter-spacing: 0.1rem;
  font-size: 2.5rem;
}

.feed-home-dash {
  width: 8rem;
  height: 0.2rem;
  background-color: #228be6;
  margin: auto;
}

.feed-home-steps {
  display: flex;
  margin-top: 3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  align-items: flex-start;
  justify-content: center;
}

.feed-home-step1,
.feed-home-step2,
.feed-home-step3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 18rem;
}

.feed-home-step1,
.feed-home-step2 {
  margin-right: 4rem;
}

.feed-home h4 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

@media only screen and (min-width: 1700px) {
  .feed-home {
    height: 60vh;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .feed-home {
    height: 170vh;
  }

  .feed-home-dash {
    width: 7rem;
  }

  .feed-home-steps {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }

  .feed-home-step1,
  .feed-home-step2,
  .feed-home-step3 {
    padding-top: 1rem;
  }

  .feed-home-step1,
  .feed-home-step2 {
    margin-right: 0;
  }
}
