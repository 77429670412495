.profile {
    height: 100vh;
    margin-top: 3rem;
    background-color: #4a7a9e91;
    border-top-right-radius: 40%;
}

.profile-title {
    font-size: 3rem;
    margin-left: 7rem;
    padding-top: 3rem;
}

.profile-container {
    display: flex;
    margin-left: 10rem;
}

.profile-leftside {
    flex: 30%;
}

.profile-rightside {
    flex: 70%;
    margin-right: 10rem;
}

.profile-name {
    font-size: 1rem;
    text-align: center;
}

.profile-text {
    font-size: 1rem;
    padding-right: 5rem;
    margin-top: 0;
    margin-left: 5rem;
    font-weight: 500;
}

.profile-image {
    border-radius: 5%;
}

@media only screen and (min-width: 1700px) {
    .profile {
        margin-top: -12rem;
        height: 70vh;
    }
    .profile-title {
        margin-left: 20rem;
    }
    .profile-name {
        margin-right: 2rem;
    }
    .profile-text {
        font-size: 1.3rem;
        margin-left: 2rem;
    }
    .profile-image {
        margin-left: 3rem;
    }
}

@media only screen and (min-width: 1350px) and (max-width: 1500px) {
    .profile {
        margin-top: -12rem;
        height: 90vh;
    }
    .profile-title {
        margin-left: 10rem;
    }
    .profile-name {
        margin-right: 2rem;
    }
    .profile-text {
        font-size: 1.3rem;
        margin-left: 2rem;
    }
    .profile-image {
        margin-left: 3rem;
    }
}

@media only screen and (min-width: 1501px) and (max-width: 1699px) {
    .profile {
        margin-top: -12rem;
        height: 80vh;
    }
    .profile-title {
        margin-left: 10rem;
    }
    .profile-name {
        margin-right: 2rem;
    }
    .profile-text {
        font-size: 1.3rem;
        margin-left: 2rem;
    }
    .profile-image {
        margin-left: 3rem;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1600px) {
    .profile {
        height: 90vh;
    }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
    .profile {
        margin-top: -6rem;
        border-top-right-radius: 10rem;
        height: 170vh;
    }

    .profile-title {
        font-size: 2.5rem;
        margin-left: 1rem;
    }

    .profile-container {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
    }

    .profile-leftside {
        flex: 0%;
    }

    .profile-rightside {
        flex: 0%;
        margin-right: 2rem;
    }

    .profile-name {
        font-size: 1rem;
        text-align: center;
    }

    .profile-text {
        font-size: 1rem;
        padding-right: 0rem;
        margin-top: 0;
        margin-left: 0rem;
        text-align: justify;
    }

    .profile-image {
        border-radius: 5%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 399px) {
    .profile {
        margin-top: -6rem;
        border-top-right-radius: 10rem;
        height: 185vh;
    }

    .profile-title {
        font-size: 2.5rem;
        margin-left: 1rem;
    }

    .profile-container {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
    }

    .profile-leftside {
        flex: 0%;
    }

    .profile-rightside {
        flex: 0%;
        margin-right: 2rem;
    }

    .profile-name {
        font-size: 1rem;
        text-align: center;
    }

    .profile-text {
        font-size: 1rem;
        padding-right: 0rem;
        margin-top: 0;
        margin-left: 0rem;
        text-align: justify;
    }

    .profile-image {
        border-radius: 5%;
    }
}
