.contact {
    height: 80vh;
    margin-top: 3rem;
    margin-left: 7rem;
}

.contact-title {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.contact-subtitle {
    margin-top: 0;
    font-size: 1rem;
    color: #707070;
}

form {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
}

input,
textarea {
    border: none;
    border-radius: 6px;
    box-shadow: none;
    background: #f4f4f4;
    margin-bottom: 1rem;
    margin-right: 5rem;
    border: 0.1rem solid #9d9e9e;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 1.3rem;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
}

textarea {
    width: 38rem;
    font-family: "Roboto", sans-serif;
    max-width: 38rem;
    max-height: 8rem;
}

input:last-child {
    width: 25rem;
    background-color: #27445a;
    color: whitesmoke;
}

input:last-child:hover {
    cursor: pointer;
}

.contact-row1 {
    display: flex;
}

.contact-row2 {
    display: flex;
}

.contact-error {
    color: red;
    font-weight: bold;
    font-size: 0.7rem;
    margin-top: 0;
    margin-left: 1rem;
    margin-bottom: 1rem;
}

@media only screen and (min-width: 1700px) {
    .contact {
        height: 55vh;
        margin-top: 5rem;
        margin-left: 20rem;
    }
}

@media only screen and (min-width: 1350px) and (max-width: 1699px) {
    .contact {
        height: 60vh;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1600px) {
    .contact {
        height: 70vh;
    }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
    .contact {
        height: 80vh;
        margin-top: 3rem;
        margin-left: 1.5rem;
        padding-bottom: 2rem;
    }

    .contact-title {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    .contact-subtitle {
        font-size: 0.9rem;
    }

    form {
        margin-top: 3rem;
    }

    input,
    textarea {
        border: none;
        border-radius: 6px;
        box-shadow: none;
        background: #f4f4f4;
        margin-bottom: 0.5rem;
        margin-right: 1rem;
        border: 0.1rem solid #9d9e9e;
        font-size: 1.3rem;
        padding-left: 0.5rem;
        margin-bottom: 0.5rem;
        width: 320px;
    }

    .contact-row1,
    .contact-row2 {
        display: flex;
        flex-direction: column;
    }

    textarea {
        width: 320px;
        max-width: 320px;
    }

    input:last-child {
        width: 15rem;
    }
}

@media only screen and (min-width: 0px) and (max-width: 399px) {
    .contact {
        height: 85vh;
        margin-top: 3rem;
        margin-left: 1.5rem;
        padding-bottom: 2rem;
    }

    .contact-title {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    .contact-subtitle {
        font-size: 0.9rem;
    }

    form {
        margin-top: 3rem;
    }

    input,
    textarea {
        border: none;
        border-radius: 6px;
        box-shadow: none;
        background: #f4f4f4;
        margin-bottom: 0.5rem;
        margin-right: 1rem;
        border: 0.1rem solid #9d9e9e;
        font-size: 1.3rem;
        padding-left: 0.5rem;
        margin-bottom: 0.5rem;
        width: 320px;
    }

    .contact-row1,
    .contact-row2 {
        display: flex;
        flex-direction: column;
    }

    textarea {
        width: 320px;
        max-width: 320px;
    }

    input:last-child {
        width: 15rem;
    }
}
