nav {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  padding: 0.5rem 0rem;
  background-color: #383e46;
  color: whitesmoke;
  z-index: 1000;
}

.header-title {
  margin-left: 4rem;
  margin-bottom: 0.4rem;
}

.header-menu {
  margin-left: auto;
}

.header-menu ul {
  display: flex;
  padding: 0 1rem;
}
.header-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.header-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: whitesmoke;
  font-weight: 500;
}

.header-menu li a:hover {
  color: #141414;
}

.header-button {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #606977;
  color: whitesmoke;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.header-button:hover {
  background-color: #2642af;
}

@media screen and (max-width: 768px) {
  .header-button {
    display: block;
  }
  .header-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;

    display: none;
  }
  .header-menu li {
    text-align: center;
    margin: 0;
  }
  .header-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  .header-menu li:hover {
    background-color: #eee;
  }
  .header-menu.expanded ul {
    display: block;
  }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  nav {
    height: 60px;
    width: 100vw;
  }

  .header-title {
    margin-left: 2rem;
    margin-bottom: 0.4rem;
  }
}
