.footer-container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  background-color: #94afc4;
  padding: 0 2rem;
}

.footer-info {
  display: flex;
  font-size: 1.2rem;
  justify-content: center;
}

.footer-network img:hover {
  cursor: pointer;
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    background-color: #94afc4;
    padding: 0 2rem;
  }

  .footer-info {
    display: flex;
    font-size: 1.2rem;
    justify-content: center;
  }
}
