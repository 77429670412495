.bio-work {
  margin-top: 4rem;
}

.bio-work-container {
  background-color: #ffffff;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.bio-work-title {
  font-size: 2rem;
  text-align: center;
}

.bio-work-dash {
  width: 8rem;
  height: 0.2rem;
  background-color: #228be6;
  margin: auto;
}

.bio-work-steps {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-top: 3rem;
}

.bio-work-step1::after {
  content: "";
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #383e4641;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bio-work-textcontainer-1 {
  display: flex;
}

.bio-work-steptitle1 {
  display: flex;
}

.bio-work-number {
  font-size: 1.5rem;
  color: #228be6;
}

.bio-work-subtitle {
  font-size: 1.5rem;
  margin-left: 0.2rem;
  font-weight: 600;
}

.bio-work-number::after {
  content: "\00a0";
}

.bio-work-info1 {
  display: flex;
  flex-direction: column;
}

.bio-work-text1 {
  text-align: justify;
  margin-right: 10rem;
}

.bio-work-image1 {
  margin-right: 5rem;
  transform: translateY(-1rem);
}

.bio-work-step2::after {
  content: "";
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #383e464b;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bio-work-info2 {
  display: flex;
}

.bio-work-infocontainer1,
.bio-work-infocontainer2,
.bio-work-infocontainer3,
.bio-work-infocontainer4 {
  width: 25%;
  text-align: center;
}

.bio-work-textcontainer1,
.bio-work-textcontainer2,
.bio-work-textcontainer3,
.bio-work-textcontainer4 {
  text-align: justify;
  margin-right: 2rem;
}

.bio-work-textcontainer-3 {
  display: flex;
}

.bio-work-info3 {
  margin-right: 4rem;
}

.bio-work-image3 {
  margin-right: 5rem;
  transform: translateY(1rem);
}

.bio-work-image3 img {
  border-radius: 4rem;
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .bio-work-container {
    padding-top: 3.5rem;
    padding-bottom: 4rem;
  }

  .bio-work-dash {
    width: 7rem;
  }

  .bio-work-steps {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 3rem;
  }

  .bio-work-step1::after {
    margin-left: 0px;
  }

  .bio-work-textcontainer-1 {
    display: flex;
    flex-direction: column;
  }

  .bio-work-steptitle1 {
    display: flex;
  }

  .bio-work-info1 {
    display: flex;
    flex-direction: column;
  }

  .bio-work-text1 {
    text-align: justify;
    margin-right: 1.5rem;
  }

  .bio-work-image1 {
    margin-right: auto;
    margin-left: auto;
  }

  .bio-work-step2::after {
    margin-left: 0px;
  }

  .bio-work-info2 {
    display: flex;
    flex-direction: column;
  }

  .bio-work-infocontainer1,
  .bio-work-infocontainer2,
  .bio-work-infocontainer3,
  .bio-work-infocontainer4 {
    width: 100%;
    text-align: center;
  }

  .bio-work-textcontainer1,
  .bio-work-textcontainer2,
  .bio-work-textcontainer3,
  .bio-work-textcontainer4 {
    margin-right: auto;
    margin-left: auto;
  }

  .bio-work-textcontainer-3 {
    display: flex;
    flex-direction: column;
  }

  .bio-work-info3 {
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
  }

  .bio-work-image3 {
    margin-right: auto;
    margin-left: auto;
  }
}
