/* * {
  outline: 1px dotted red;
} */

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  background-color: whitesmoke;
  /* overflow-x: hidden; */
}

html,
body {
  max-width: 100%;
}

body {
  padding: 0;
  margin: 0;
}
