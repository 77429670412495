.feed-helps {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.feed-helps-container {
  margin-left: 8rem;
}

.feed-helps-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 4rem;
  flex-wrap: wrap;
  width: 40rem;
}

.feed-helps-list {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  padding-left: 0;
  line-height: 2rem;
}

.feed-helps-list li {
  list-style-position: inside;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
  .feed-helps {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .feed-helps-container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .feed-helps-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 4rem;
    flex-wrap: wrap;
    width: auto;
  }

  .feed-helps-list li {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
